<template lang="pug">
head-meta(title-page="Политика конфиденциальности сайта ChiedoHome", description="Политика конфиденциальности")
the-policy
</template>

<script>
import ThePolicy from '../components/policy/ThePolicy'
import HeadMeta from '../components/_layout/HeadMeta'

export default {
  components: { HeadMeta, ThePolicy },
  name: 'PolicyPage'
}
</script>
